<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> {{ $route.meta.title }}
      </div>

      <div class="card p-fluid w-full">

        <Toast />

        <div class="grid formgrid">
          <div class="field col-8">
            <label for="nome">Nome</label>
            <InputText id="nome" v-model.trim="form.nome" required="true" autofocus autocomplete="off"
              :class="{ 'p-invalid': submitted && !form.nome }" disabled />
            <small class="p-error" v-if="submitted && !form.nome">Nome é obrigatório.</small>
          </div>

          <div class="field col-4">
            <label for="documento">Documento</label>
            <InputText id="documento" v-model="form.documento" required="true"
              :class="{ 'p-invalid': submitted && !form.documento }" disabled />
            <small class="p-error" v-if="submitted && !form.documento">Documento é obrigatório.</small>
          </div>

          <Panel header="Endereços" class="field col-12">
            <DataTable :value="form.enderecos" responsiveLayout="scroll">
              <Column field="logradouro" header="Logradouro"></Column>
              <Column field="complemento" header="Complemento"></Column>
              <Column field="cep" header="CEP"></Column>
              <Column field="cidade" header="Cidade"></Column>
              <Column field="uf" header="UF"></Column>
              <Column field="tipo" header="Tipo">
                <template #body="{ data }">
                  {{ (tiposEndereco.find(t => t.value === data.tipo))?.label }}
                </template>
              </Column>
              <Column field="principal" header="Principal">
                <template #body="{ data }">
                  <i class="pi pi-check" v-if="data.principal"></i>
                </template>
              </Column>
            </DataTable>
          </Panel>

          <Panel header="Telefones" class="field col-12">
            <DataTable :value="form.telefones" responsiveLayout="scroll">
              <Column field="numero" header="Número">
                <template #body="{ data }">
                  {{ $filters.telefone(data.numero) }}
                </template>
              </Column>
              <Column field="tipo" header="Tipo">
                <template #body="{ data }">
                  {{ (tiposTelefone.find(t => t.value === data.tipo))?.label }}
                </template>
              </Column>
              <Column field="validado" header="Validado">
                <template #body="{ data }">
                  <i class="pi pi-check" v-if="data.validado"></i>
                </template>
              </Column>
              <Column field="classificacao" header="Classificação">
                <template #body="{ data }">
                  {{ (classificacoesTelefone.find(t => t.value === data.classificacao))?.label }}
                </template>
              </Column>
              <Column field="principal" header="Principal">
                <template #body="{ data }">
                  <i class="pi pi-check" v-if="data.principal"></i>
                </template>
              </Column>
            </DataTable>
          </Panel>

          <Panel header="E-mails" class="field col-12">
            <DataTable :value="form.emails" responsiveLayout="scroll">
              <Column field="email" header="E-mail"></Column>
              <Column field="tipo" header="Tipo">
                <template #body="{ data }">
                  {{ (tiposEmail.find(t => t.value === data.tipo))?.label }}
                </template>
              </Column>
              <Column field="validado" header="Validado">
                <template #body="{ data }">
                  <i class="pi pi-check" v-if="data.validado"></i>
                </template>
              </Column>
              <Column field="principal" header="Principal">
                <template #body="{ data }">
                  <i class="pi pi-check" v-if="data.principal"></i>
                </template>
              </Column>
            </DataTable>
          </Panel>

        </div>

        <div class="flex mt-5">
          <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto"
            @click="$router.push('/devedores')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrudService from '@/services/crud'

export default {
  data() {
    return {
      form: {},
      submitted: false,
      tiposTelefone: [
        { value: 'CELULAR', label: 'Celular' },
        { value: 'RESIDENCIAL', label: 'Residencial' },
        { value: 'COMERCIAL', label: 'Comercial' },
      ],
      classificacoesTelefone: [
        { value: 'NOVO', label: 'Novo' },
        { value: 'RUIM', label: 'Ruim' },
        { value: 'BOM', label: 'Bom' },
        { value: 'HOT', label: 'Hot' },
      ],
      tiposEndereco: [
        { value: 'RESIDENCIAL', label: 'Residencial' },
        { value: 'COMERCIAL', label: 'Comercial' },
      ],
      tiposEmail: [
        { value: 'PESSOAL', label: 'Pessoal' },
        { value: 'PROFISSIONAL', label: 'Profissional' },
      ],
    }
  },
  async mounted() {
    this.service = new CrudService('/admin/devedores')

    if (this.$route.params.id) {
      const { data } = await this.service.findById(this.$route.params.id)
      this.form = data
    }
  }
}
</script>

<style scoped lang="scss">

</style>
